import { DialogService }      from 'aurelia-dialog';
import { bindable, inject }   from 'aurelia-framework';
import { I18N }               from 'aurelia-i18n';
import { BaseListViewModel }  from 'base-list-view-model';
import { CreateCommentModal } from 'modules/aggregates/comments/create';
import { CommentsRepository } from 'modules/aggregates/comments/services/repository';
import { ViewCommentModal }   from 'modules/aggregates/comments/view';
import { AppContainer }       from 'resources/services/app-container';
import { Downloader }         from 'resources/services/downloader';

@inject(AppContainer, CommentsRepository, I18N, Downloader, DialogService)
export class ListComments extends BaseListViewModel {

    listingId = 'comments-listing';

    @bindable headerTitle    = null;
    @bindable newRecordRoute = 'comments.create';
    @bindable repository;
    @bindable datatable;
    @bindable listing;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, dialogService) {
        super(appContainer);

        this.i18N          = i18N;
        this.repository    = repository;
        this.downloader    = downloader;
        this.dialogService = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'comments.manage',
            'comments.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.relatableModel = params.model;
        this.relatableId    = this.relatableModel.test_header_id;
        this.readonly       = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:               {
                action:  (row) => this.openModal({ relatableId: this.relatableId, id: row.id }, ViewCommentModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['comments.manage', 'comments.view']),
            },
            destroy:            !this.readonly && this.appContainer.authenticatedUser.can(['comments.manage', 'comments.delete']),
            destroySelected:    !this.readonly,
            actionsContextMenu: true,
            actions:            [],
            buttons:            [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['comments.manage', 'comments.create']),
                    action:           () => this.openModal(this, CreateCommentModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:         true,
            sorting:            {
                column:    2,
                direction: 'desc',
            },
            columns:            [
                {
                    data:  'text',
                    name:  'comments.text',
                    title: 'form.field.comment',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'comments.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search(this.relatableId, criteria);
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
